import { BodyRegular, BodySmall } from '../../common/body';
import { H5, H6 } from '../../common/headline';
import Icon from '../../common/Icons/Icon';
import { TextLinkS } from '../../common/textLink';
import Logo from '../../common/Logos/Logo';

export const DesktopFooter = (): JSX.Element => (
  <div>
    <div className="sn-grid grid-cols-[minmax(140px,_251px)_minmax(892px,_1fr)] pb-[80px] pt-[64px]">
      <div className="mb-[32px] flex justify-center">
        <div className="relative h-[64px] w-[202px] shrink-0">
          <Logo theme="light" />
        </div>
      </div>
      <div>
        <div className="sn-grid grid-cols-4">
          <div>
            <H5>Get Started</H5>
            <div className="flex flex-col">
              <div className="mb-4 mt-[12px]">
                <TextLinkS url="/how-it-works">Weight-loss programme</TextLinkS>
              </div>
              <div className="mb-4">
                <TextLinkS url="/mounjaro-weight-loss-programme">
                  Mounjaro weight-loss programme
                </TextLinkS>
              </div>
              <div className="mb-2">
                <TextLinkS url="/faqs">FAQs</TextLinkS>
              </div>
            </div>
          </div>
          <div>
            <H5>Learn more</H5>
            <div className="mb-4 mt-[12px] flex flex-col">
              <TextLinkS url="/our-story">Our Story</TextLinkS>
            </div>
            <div className="mb-4 flex flex-col">
              <TextLinkS url="https://apply.workable.com/secondnature/?utm_source=Second%20Nature%20Website">
                Careers
              </TextLinkS>
            </div>
            <div className="mb-4 flex flex-col">
              <TextLinkS url="/terms">Terms and Conditions</TextLinkS>
            </div>
            <div className="mb-4 flex flex-col">
              <TextLinkS url="/privacy">Privacy Policy</TextLinkS>
            </div>
            <div className="mb-4 flex flex-col">
              <TextLinkS url="/complaints">Complaints Procedure</TextLinkS>
            </div>
            <div className="mb-2 flex flex-col">
              <TextLinkS url="/pharmacy-services">Pharmacy Services</TextLinkS>
            </div>
          </div>
          <div>
            <H5>Resources</H5>
            <div className="flex flex-col">
              <div className="mb-4 mt-[12px]">
                <TextLinkS url="/guides">Recipes and Guides</TextLinkS>
              </div>
              <div className="mb-4">
                <TextLinkS url="/stories">Success Stories</TextLinkS>
              </div>
              <div className="mb-4">
                <TextLinkS url="/blog">Blog</TextLinkS>
              </div>
              <div className="mb-2">
                <TextLinkS url="https://shop.secondnature.io/">Shop</TextLinkS>
              </div>
            </div>
          </div>
          <div>
            <H5>Partners</H5>
            <div className="mb-2 mt-[12px] flex flex-col">
              <TextLinkS url="/nhs-and-partnerships">
                NHS and Partnerships
              </TextLinkS>
            </div>
          </div>
        </div>
        <div className="sn-grid grid-cols-1">
          <div className="mt-[62px] flex w-[88px] justify-between">
            <a
              className="fill-mono-white stroke-mono-white"
              href="https://www.facebook.com/secondnature.io"
              aria-label="Facebook"
            >
              <Icon name="meta" />
            </a>
            <a
              className="fill-mono-white stroke-mono-white"
              href="https://twitter.com/snhealth_uk"
              aria-label="Twitter"
            >
              <Icon name="twitter" />
            </a>
            <a
              className="fill-mono-white stroke-mono-white"
              href="https://www.instagram.com/secondnature_health"
              aria-label="Instagram"
            >
              <Icon name="instagram" />
            </a>
          </div>

          <div className="flex flex-row items-center">
            <H6>Second Nature®</H6>
            <div className="mx-[18px]">
              <BodyRegular>483 Green Lanes, London, N13 4BS</BodyRegular>
            </div>
            <div className="h-full w-0 rounded-full border-r-[1.5px] border-mono-white"></div>
            <div className="mx-[18px]">
              <div className="flex">
                <BodyRegular>Email:</BodyRegular>
                <TextLinkS url="mailto:hello@secondnature.io">
                  <p className="ml-[4px] font-medium">hello@secondnature.io</p>
                </TextLinkS>
              </div>
            </div>
            <div className="h-full w-0 rounded-full border-r-[1.5px] border-mono-white"></div>
            <div className="mx-[18px]">
              <div className="flex">
                <BodyRegular>Press:</BodyRegular>
                <TextLinkS url="mailto:press@secondnature.io">
                  <p className="ml-[4px] font-medium">press@secondnature.io</p>
                </TextLinkS>
              </div>
            </div>
          </div>

          <div className="flex flex-col text-mono-white/30">
            <BodySmall>
              Registered Pharmacy: Pharmalogic, 464 Ranglet Road, Walton Summit
              Centre, Bamber Bridge, Preston, Lancashire, PR5 8AR, United
              Kingdom
            </BodySmall>
            <div className="mb-4">
              <BodySmall>
                Registration: GPhC 9010938, Superintendent Pharmacist: Haroon
                Amanji
              </BodySmall>
            </div>
            <br />
            <BodySmall>
              Registration No: 08511152 registered in England and Wales
            </BodySmall>
            <BodySmall>
              © Second Nature Healthy Habits Ltd {new Date().getFullYear()}
            </BodySmall>
          </div>
        </div>
      </div>
    </div>
  </div>
);
