import { useRouter } from 'next/router';
import { useState } from 'react';

import { H2, H3, H6 } from '@common/headline';
import { BodyLarge, BodyRegular } from '@common/body';

import { ProgrammeCardNoPricing } from '../Card/ProgrammeCardNoPricing';

import programmePhone from '@public/img/how-it-works/programme-phone.png';
import programmeHealthbox from '@public/img/how-it-works/programme-healthbox.png';

type Props = {
  utmMedium?: string;
  utmCampaign?: string;
};

export const ProgrammeSelection = ({
  utmMedium,
  utmCampaign,
}: Props): JSX.Element | null => {
  // Needed only for mobile screens where only one product displays at a time
  const [isDisplayingGLP1Product, setIsDisplayingGLP1Product] = useState(true);

  const { locale } = useRouter();
  if (locale !== 'uk') {
    return null;
  }

  const headlineChildren = (
    <>
      Your journey, your <span className="text-teal">personalised</span>{' '}
      programme.
    </>
  );
  const subheadingText =
    'A clinically-proven, sustainable way to lose weight and gain healthy habits for life. Developed by top dietitians and psychologists.';
  const footerReferences = [
    <span key="1">
      <sup>1</sup> Aronne LJ, Sattar N, Horn DB, et al. Continued Treatment With
      Tirzepatide for Maintenance of Weight Reduction in Adults With Obesity:
      The SURMOUNT-4 Randomized Clinical Trial. JAMA. 2024;331(1):38–48.
      doi:10.1001/jama.2023.24945
    </span>,
    <span key="2">
      <sup>2</sup> Ross JAD, Barron E, McGough B, Valabhji J, Daff K, Irwin J,
      Henley WE, Murray E. Uptake and impact of the English National Health
      Service digital diabetes prevention programme: observational study. BMJ
      Open Diabetes Res Care. 2022 May;10(3):e002736. doi:
      10.1136/bmjdrc-2021-002736. PMID: 35504697; PMCID: PMC9066480.
    </span>,
  ];
  const currentDotStyle = 'h-[10px] w-[10px] rounded bg-purple';
  const defaultDotStyle = 'h-[8px] w-[8px] rounded-[3px] bg-blue-600';

  // Creating separate mobile and desktop displays despite duplication
  // as it's quite confusing what's expected for each layout when the
  // HTML is combined
  const mobileDisplay = (
    <div className="bg-blue px-3 pb-[60px] pt-20 text-mono-white">
      <div className="container m-auto flex max-w-[351px] flex-col items-center text-center">
        <div className="mb-2">
          <H3>{headlineChildren}</H3>
        </div>
        <div className="mb-[45px] text-blue-50">
          <BodyRegular>{subheadingText}</BodyRegular>
        </div>
        <div className="mb-6 flex w-[26px] flex-row items-center justify-between">
          <div
            className={
              isDisplayingGLP1Product ? currentDotStyle : defaultDotStyle
            }
          />
          <div
            className={
              isDisplayingGLP1Product ? defaultDotStyle : currentDotStyle
            }
          />
        </div>
        <div className="flex w-full flex-col items-center justify-between">
          <div className={isDisplayingGLP1Product ? '' : 'hidden'}>
            <ProgrammeCardNoPricing
              medicationText="Weight-loss medication included"
              glp1MedicationIncluded="Yes"
              image={programmeHealthbox}
              altText="Medication-supported programme"
              weightLossPercentage={
                <span>
                  25%<sup>1</sup>
                </span>
              }
              utmMedium={utmMedium}
              utmCampaign={utmCampaign}
            />
          </div>
          <div className={isDisplayingGLP1Product ? 'hidden' : ''}>
            <ProgrammeCardNoPricing
              medicationText="Weight-loss medication included"
              glp1MedicationIncluded="No"
              image={programmePhone}
              altText="Second Nature programme"
              weightLossPercentage={
                <span>
                  7-10%<sup>2</sup>
                </span>
              }
              utmMedium={utmMedium}
              utmCampaign={utmCampaign}
            />
          </div>
        </div>
        <div className="mt-8 flex items-center">
          <div className="mr-2">
            <H6>Original programme</H6>
          </div>
          <label className="relative inline-flex cursor-pointer items-center">
            {/* hidden is used in the following HTML element because we don't want the checkbox itself to show */}
            <input
              type="checkbox"
              checked={isDisplayingGLP1Product}
              onChange={() =>
                setIsDisplayingGLP1Product(!isDisplayingGLP1Product)
              }
              className="peer hidden"
            />
            <div className="h-8 w-[63px] rounded-full bg-dawn-blue after:absolute after:left-[8px] after:top-[4px] after:h-6 after:w-6 after:rounded-full after:bg-mono-white after:transition-all peer-checked:after:translate-x-full" />
          </label>
          <div className="ml-2">
            <H6>Medication-supported</H6>
          </div>
        </div>
        <div className="mt-12 text-blue-200">
          {isDisplayingGLP1Product ? (
            <>
              <BodyRegular>{footerReferences[0]}</BodyRegular>
              <BodyRegular>{footerReferences[1]}</BodyRegular>
            </>
          ) : (
            <BodyRegular>{footerReferences[2]}</BodyRegular>
          )}
        </div>
      </div>
    </div>
  );

  const desktopDisplay = (
    <div className="bg-blue px-[60px] pb-20 pt-[88px] text-mono-white">
      <div className="container m-auto flex max-w-[940px] flex-col items-center text-center">
        <div className="mb-6">
          <H2>{headlineChildren}</H2>
        </div>
        <div className="mb-[72px]">
          <BodyLarge>{subheadingText}</BodyLarge>
        </div>
        <div className="flex w-full max-w-[872px] items-center justify-between">
          <ProgrammeCardNoPricing
            medicationText="Weight-loss medication included"
            glp1MedicationIncluded="Yes"
            image={programmeHealthbox}
            altText="Medication-supported programme"
            weightLossPercentage={
              <span>
                25%<sup>1</sup>
              </span>
            }
            utmMedium={utmMedium}
            utmCampaign={utmCampaign}
          />
          <ProgrammeCardNoPricing
            medicationText="Weight-loss medication included"
            glp1MedicationIncluded="No"
            image={programmePhone}
            altText="Second Nature programme"
            weightLossPercentage={
              <span>
                7-10%<sup>2</sup>
              </span>
            }
            utmMedium={utmMedium}
            utmCampaign={utmCampaign}
          />
        </div>
        <div className="mt-16 max-w-[872px] text-blue-400">
          <BodyRegular>{footerReferences[0]}</BodyRegular>
          <br />
          <BodyRegular>{footerReferences[1]}</BodyRegular>
          <br />
          <BodyRegular>{footerReferences[2]}</BodyRegular>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="lg:hidden">{mobileDisplay}</div>
      <div className="hidden lg:block">{desktopDisplay}</div>
    </>
  );
};
