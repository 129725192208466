import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  ReactNode,
  Dispatch,
} from 'react';

import {
  getLocalStorage,
  setLocalStorage,
} from '@components/NhsSignup/helpers/localStorage';
import {
  ActionDispatch,
  VitalityQuizActionType,
} from '@components/VitalityQuiz/state/actions';
import { reducer, ReducerState } from '@components/VitalityQuiz/state/reducer';
import {
  initialState,
  VitalityQuizStateType,
} from '@components/VitalityQuiz/state/state';
import { useRedirectToStartOfQuizIfNewUser } from '@hooks/useRedirectToStartOfQuizIfNewUser';

const StateContext = createContext<VitalityQuizStateType | undefined>(
  undefined,
);
const DispatchContext = createContext<ActionDispatch | undefined>(undefined);

function VitalityQuizProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, dispatch] = useReducer(
    reducer,
    getLocalStorage('cached-vitality-msp-quiz', initialState),
  );

  useRedirectToStartOfQuizIfNewUser(state.hasStartedQuiz, {
    basePath: '/partnerships/sn-vitality',
    redirectPath: '/partnerships/sn-vitality',
  });

  useEffect(() => {
    setLocalStorage('cached-vitality-msp-quiz', state);
  }, [state]);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>{children}</StateContext.Provider>
    </DispatchContext.Provider>
  );
}

const useVitalityQuizContext = (): ReducerState => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error(
      'useVitalityQuizContext must be used within a StateProvider',
    );
  }
  return context;
};

const useVitalityQuizDispatchContext = (): Dispatch<VitalityQuizActionType> => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error(
      'useVitalityQuizDispatchContext must be used within a DispatchProvider',
    );
  }
  return context;
};

export const useVitalityQuiz = (): {
  state: VitalityQuizStateType;
  dispatch: Dispatch<VitalityQuizActionType>;
} => {
  const state = useVitalityQuizContext();
  const dispatch = useVitalityQuizDispatchContext();

  return { state, dispatch };
};

export { VitalityQuizProvider as default };
