import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

type RedirectConfig = {
  basePath: string;
  redirectPath: string;
};

/**
 * If a user is on a quiz results page but hasn't started the quiz,
 * redirect them to the start of the quiz as they haven't answered the required questions.
 *
 * Example usage:
 * useRedirectToStartOfQuizIfNewUser(state.hasStartedQuiz, {
 *   basePath: '/get-plan',
 *   redirectPath: '/get-plan',
 * });
 */
export const useRedirectToStartOfQuizIfNewUser = (
  hasStarted: boolean,
  config: RedirectConfig,
): void => {
  const router = useRouter();
  const [previousRoute, setPreviousRoute] = useState('');

  useEffect(() => {
    // Function to update the previous route
    const handleRouteChange = () => {
      setPreviousRoute(router.asPath);
    };

    // Subscribe to route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup subscription on unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    // Check if the user is on a basePath page, has not started the quiz, and the user did not just navigate from the basePath page
    if (
      router.asPath.startsWith(config.basePath) &&
      !hasStarted &&
      !previousRoute.startsWith(config.basePath)
    ) {
      // If it is, navigate the user to the redirectPath page
      router.push(config.redirectPath);
    }
  }, [hasStarted, previousRoute, router, config]);
};
